.btn-secondary
{
    @include button-variant($secondary, $colorBaseMid);
}

.color-accent
{
    color: $colorAccent;
}

.color-base-light
{
    color: $colorBaseLight;
}

.color-inverse
{
    color: $colorInverse;
}

.color-students
{
    color: $colorStudents;
}

.color-residential
{
    color: $colorResidential;
}

.color-landlords
{
    color: $colorLandlords;
}

.color-studios
{
    color: $colorStudios;
}


.bg-color-accent
{
    background-color: $colorAccent;
}

.bg-color-base-light
{
    background-color: $colorBaseLight;
}

.bg-color-inverse
{
    background-color: $colorInverse;
}

.bg-color-students
{
    background-color: $colorStudents;
}

.bg-color-residential
{
    background-color: $colorResidential;
}

.bg-color-landlords
{
    background-color: $colorLandlords;
}

.bg-color-studios
{
    background-color: $colorStudios;
}

.color-overlay
{
    &::before
    {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &.overlay-accent::before
    {
        background-color: rgba($colorAccent, $mainOpacity);
    }

    &.overlay-base-light
    {
        background-color: rgba($colorBaseLight, $mainOpacity);
    }

    &.overlay-inverse::before
    {
        background-color: rgba($colorInverse, $mainOpacity);
    }

    &.overlay-students::before
    {
        background-color: rgba($colorStudents, $mainOpacity);
    }

    &.overlay-residential::before
    {
        background-color: rgba($colorResidential, $mainOpacity);
    }

    &.overlay-landlords::before
    {
        background-color: rgba($colorLandlords, $mainOpacity);
    }

    &.overlay-studios::before
    {
        background-color: rgba($colorStudios, $mainOpacity);
    }
}

.btn-primary i
{
    color: $colorAlternate;
}
