body.no-js
{
    .no-js-hidden
    {
        display: none;
    }

    .no-js-inline
    {
        display: inline;
    }

    .no-js-block
    {
        display: block;
    }

    .no-js-inline-block
    {
        display: inline-block;
    }

    .no-js-flex
    {
        display: flex;
    }

    .no-js-inline-flex
    {
        display: inline-flex;
    }

    .no-js-grid
    {
        display: grid;
    }

    .no-js-inline-grid
    {
        display: inline-grid;
    }
}

body.js
{
    .js-hidden
    {
        display: none;
    }

    .js-inline
    {
        display: inline;
    }

    .js-block
    {
        display: block;
    }

    .js-inline-block
    {
        display: inline-block;
    }

    .js-flex
    {
        display: flex;
    }

    .js-inline-flex
    {
        display: inline-flex;
    }

    .js-grid
    {
        display: grid;
    }

    .js-inline-grid
    {
        display: inline-grid;
    }
}
