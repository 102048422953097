@mixin keyframes($name)
{
    @-webkit-keyframes #{$name}
    {
        @content;
    }

    @-moz-keyframes #{$name}
    {
        @content;
    }

    @-ms-keyframes #{$name}
    {
        @content;
    }

    @keyframes #{$name}
    {
        @content;
    }
}

@mixin animate($name, $time: 1s, $algor: linear, $delay: "", $loop: infinite,$fill: "")
{
    -webkit-animation: #{$name} $time $algor #{$delay} $loop #{$fill};
    -moz-animation: #{$name} $time $algor #{$delay} $loop #{$fill};
    -ms-animation: #{$name} $time $algor #{$delay} $loop #{$fill};
    animation: #{$name} $time $algor #{$delay} $loop #{$fill};
}

.christmas-banner
{
    @extend .banner;
    @include animate(snow, 20s);
}

/*Keyframes*/
@include keyframes(snow)
{
    0%
    {
        background-position: 0px 0px, 0px 0px, 0px 0px;
    }

    100%
    {
        background-position: 500px 1000px, 400px 400px, 300px 300px;
    }
}
