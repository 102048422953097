.filter-wrapper
{
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 5;

    @include media-breakpoint-down(md)
    {
        position: static;
    }
}

/* Property Search - Filter */

.search-fields-container
{
    background-color: $colorInverse;
    margin: 0;
    padding: $containerPaddingY 0;

    > form div:last-child
    {
        text-align: right;
    }

    .search-fields
    {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
        gap: 1.5rem;
        margin-bottom: 2rem;

        > .form-group
        {
            position: relative;
        }

        input[type=date] ~ label
        {
            position: absolute;
            top: 0;
            left: 0;
            width: calc(100% - 2em);
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            pointer-events: none;
        }

        input[type=date]:active ~ label,
        input[type=date]:focus ~ label,
        input[type=date]:invalid ~ label,
        input[type=date]:not(.empty) ~ label
        {
            display: none;
        }
    }

    .filter-actions
    {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1.5rem;

        @include media-breakpoint-down(md)
        {
            flex-direction: column;
            gap: 1rem;
        }

        label
        {
            color: $colorAccent;
            text-transform: lowercase;
            font-weight: 800;
            font-size: 1rem;
        }

        .action-reset
        {
            font-size: .9rem;
            text-transform: lowercase;
            color: #ccc;
            border: 0;
            padding: 0.375rem 0.75rem;
            background-color: transparent;
        }

        .action-update
        {
            display: flex;
            gap: 1.5rem;
            align-items: center;

            @include media-breakpoint-down(md)
            {
                flex-direction: column;
                gap: 1rem;
            }
        }
    }

    @include media-breakpoint-down(md)
    {
        h3
        {
            text-align: center;
        }
    }
}


#filter-news .search-fields,
#filter-faqs .search-fields
{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;

    input, select
    {
        height: 100%;
    }

    .btn
    {
        width: 100%
    }

    @include media-breakpoint-down(lg)
    {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
    }

    @include media-breakpoint-down(md)
    {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(4, 1fr);
    }
}

.filter-toggle
{
    background-color: $colorBaseLight;

    > .container
    {
        display: flex;
        justify-content: flex-end;
    }

    button
    {
        padding: 1rem;
        min-width: 12rem;
        text-align: center;
    }
}
