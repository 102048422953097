ul.breadcrumb .breadcrumb-item
{

    a,
    span
    {
        text-decoration: none;
        font-size: .8rem;
        font-weight: 400;
        text-transform: lowercase;
    }

    &::before
    {
        color: $colorStudents;
        font-weight: 400;
    }
}

.section-students
{
    ul.breadcrumb .breadcrumb-item::before
    {
        color: $colorStudents;
    }
}

.section-landlords
{
    ul.breadcrumb .breadcrumb-item::before
    {
        color: $colorLandlords;
    }
}

.section-residential
{
    ul.breadcrumb .breadcrumb-item::before
    {
        color: $colorResidential;
    }
}

.section-studios
{
    ul.breadcrumb .breadcrumb-item::before
    {
        color: $colorStudios;
    }
}
