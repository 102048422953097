@font-face
{
    font-family: 'Mont';
    font-style: normal;
    font-weight: 300;
    font-display: fallback;
    src: url('Mont-Book.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('Mont-Book.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face
{
    font-family: 'Mont';
    font-style: italic;
    font-weight: 300;
    font-display: fallback;
    src: url('Mont-BookItalic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('Mont-BookItalic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face
{
    font-family: 'Mont';
    font-style: normal;
    font-weight: 400;
    font-display: fallback;
    src: url('Mont-Regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('Mont-Regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face
{
    font-family: 'Mont';
    font-style: italic;
    font-weight: 400;
    font-display: fallback;
    src: url('Mont-RegularItalic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('Mont-RegularItalic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face
{
    font-family: 'Mont';
    font-style: normal;
    font-weight: 700;
    font-display: fallback;
    src: url('Mont-Bold.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('Mont-Bold.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face
{
    font-family: 'Mont';
    font-style: italic;
    font-weight: 700;
    font-display: fallback;
    src: url('Mont-BoldItalic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('Mont-BoldItalic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face
{
    font-family: 'Mont';
    font-style: normal;
    font-weight: 800;
    font-display: fallback;
    src: url('Mont-Heavy.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('Mont-Heavy.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face
{
    font-family: 'Mont';
    font-style: italic;
    font-weight: 800;
    font-display: fallback;
    src: url('Mont-HeavyItalic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('Mont-HeavyItalic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}