/* Headings */

@media (max-width: 1120px)
{
    h1,
    .h1
    {
        font-size: 8vw;
        font-size: clamp(4rem, 8vw, 5.5rem);
    }

    h2,
    .h2
    {
        font-size: 6vw;
        font-size: clamp(3rem, 6vw, 4rem);
    }

    h3,
    .h3
    {
        font-size: 4vw;
        font-size: clamp(2.25rem, 4vw, 3em);
    }

    h4,
    .h4
    {
        font-size: 3vw;
        font-size: clamp(1.75rem, 3vw, 2rem);
    }

    h5,
    .h5
    {
        font-size: 2vw;
        font-size: clamp(1.25rem, 2vw, 1.5rem);
    }
}