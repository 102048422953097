h1,
h2,
h3,
h4,
h5,
h6
{
    font-family: $mainFont;
    font-weight: 800;
    color: $colorAccent;
    text-transform: lowercase;
}

h1,
.h1
{
    font-size: 5.5rem;
}

h2,
.h2
{
    font-size: 4rem;
}

h3,
.h3
{
    font-size: 3rem;
}

h4,
.h4
{
    font-size: 2rem;
}

h5,
.h5
{
    font-size: 1.5rem;
}

h6,
.h6
{
    font-size: 1rem;
}

main
{
    section, aside
    {
        margin: $containerPaddingY 0;

        @include media-breakpoint-only(xs)
        {
            margin: #{($containerPaddingY / 2)} 0;
        }
    }
}

summary
{
    display: block;

    &::-webkit-details-marker
    {
        display: none;
    }
}

p
{
    font-size: 1rem;
    line-height: 1.75rem;
    margin-bottom: 1.2rem;
    font-family: $mainFont;
    color: $colorAccent;

    &:last-child
    {
        margin-bottom: 0;
    }

    @include media-breakpoint-down(md)
    {
        word-break: break-word;
    }
}

/*.ck-content a
{
    text-decoration: none;
    color: $colorStudents;
    font-weight: 800;

    &:hover
    {
        color: $colorAccent;
    }
}*/

li
{
    color: $colorAccent;
}

.font-alternate
{
    font-family: $altFont;
}
