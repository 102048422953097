.staff-list
{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 2rem;

    .profile-content
    {
        padding: 2rem;

        h5
        {
            color: $colorInverse;
        }

        .profile-title
        {
            color: $colorStudents;
        }
    }

    .card-profile
    {
        background-color: $colorAccent;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .profile-photo img
    {
        width: 100%;
        height: 320px;
        object-fit: cover;
        display: block;
    }
}
