.nav-pills
{
    box-shadow: $baseShadow;

    .nav-item a
    {
        background-color: $colorInverse;
        padding: 1rem;
        min-width: 12rem;
        border-radius: 0;
        font-weight: 800;
        font-size: 1.5rem;
        text-transform: lowercase;
        text-align: center;

        i
        {
            margin-left: .5rem;
            font-size: 1.25rem;
        }

        &.active i
        {
            color: $colorInverse;
        }

        @include media-breakpoint-down(lg)
        {
            min-width: 10rem
        }
    }
}

/* Section Colours */

.section-students .nav-pills
{
    .nav-item a.active
    {
        background-color: $colorStudents;
    }

    i
    {
        color: $colorStudents;
    }
}

.section-landlords .nav-pills
{
    .nav-item a.active
    {
        background-color: $colorLandlords;
    }

    i
    {
        color: $colorLandlords;
    }
}

.section-residential .nav-pills
{
    .nav-item a.active
    {
        background-color: $colorResidential;
    }

    i
    {
        color: $colorResidential;
    }
}

.section-studios .nav-pills
{
    .nav-item a.active
    {
        background-color: $colorStudios;
    }

    i
    {
        color: $colorStudios;
    }
}