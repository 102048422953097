@import "inc/fonts/mont";
@import "inc/variables";
@import "inc/bootstrap/critical";

:root
{
    --color-primary: #{$colorAccent};
    --color-students: #{$colorStudents};
    --color-landlords: #{$colorLandlords};
    --color-residential: #{$colorResidential};
    --color-studio: #{$colorStudios};
    --color-secondary: var(--color-primary);
}

html
{
    &.section-students
    {
        --color-secondary: var(--color-students);
    }

    &.section-landlords
    {
        --color-secondary: var(--color-landlords);
    }

    &.section-residential
    {
        --color-secondary: var(--color-residential);
    }

    &.section-studios
    {
        --color-secondary: var(--color-studio);
    }
}

div#skiplinks
{
    position: absolute;
}

body
{
    display: grid;
    grid-template-rows: min-content 1fr min-content;
    align-content: space-between;
}

.section-home main
{
    min-height: calc(100vh - 7.5rem);
}

#page-content
{
    background-color: $colorBaseLight;
    padding-top: $containerPaddingY;
    padding-bottom: $containerPaddingY;
}

/* Helper Classes */

.responsive-col-3
{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    text-align: center;
    gap: 1rem 3rem;

    @include media-breakpoint-down(xl)
    {
        grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    }

    @include media-breakpoint-down(lg)
    {
        > div:last-child
        {
            grid-column: span 2;
        }
    }

    @include media-breakpoint-down(md)
    {
        > div:last-child
        {
            grid-column: auto;
        }
    }
}

.third-col-right
{
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 1rem 2rem;
    justify-items: flex-end;
    align-items: center;

    @include media-breakpoint-down(lg)
    {
        grid-template-columns: 1fr;
        justify-items: center;
    }
}

/* Home */

.cards-home
{
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-template-rows: 1fr 1fr 1fr;
    height: 100%;

    @include media-breakpoint-down(md)
    {
        grid-template-columns: auto;
        grid-template-rows: auto;
        text-align: left;
    }

    .card-students
    {
        grid-column: span 1;
        grid-row: span 3;
        background-image: url("/site/img/home-students.jpg");
        background-size: cover;
        max-height: 100vh;
        position: sticky !important;
        top: 0;

        @include media-breakpoint-down(md)
        {
            grid-column: auto;
            grid-row: auto;
            min-height: 360px;
            position: relative !important;
        }
    }

    .card-landlords
    {
        background-image: url("/site/img/home-landlords.jpg");
    }

    .card-residential
    {
        background-image: url("/site/img/home-residential.jpg");
    }

    .card-studios
    {
        background-image: url("/site/img/home-studios.jpg");
    }

    .card-landlords, .card-residential, .card-studios
    {
        min-height: 320px;
        background-size: cover;
    }

    .card-content
    {
        padding: $containerPaddingX;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        position: relative;
        text-decoration: none;

        &:hover
        {
            .card-inner
            {
                :not(h2)
                {
                    position: static;
                    opacity: 1;
                    transition: opacity .7s linear;

                    @include media-breakpoint-down(lg)
                    {
                        display: none;
                    }
                }

                h2
                {
                    font-size: 3rem;
                }
            }
        }

        .card-inner h2::after
        {
            @include media-breakpoint-down(md)
            {
                content: '\f105';
                font-family: FontAwesome;
                font-size: 2.75rem;
                margin-left: .75rem;
            }
        }

        &.card-students
        {
            &:hover
            {
                &::before
                {
                    background-color: rgba($colorStudents, $mainOpacity);
                }
            }

            .card-inner
            {
                align-self: end;
                text-align: right;

                @include media-breakpoint-down(md)
                {
                    align-self: flex-start;
                    text-align: left;
                    order: 1;
                }
            }
        }

        &.card-landlords
        {
            &:hover
            {
                &::before
                {
                    background-color: rgba($colorLandlords, $mainOpacity);
                }
            }

            @include media-breakpoint-down(md)
            {
                order: 4;
            }
        }

        &.card-residential
        {
            &:hover
            {
                &::before
                {
                    background-color: rgba($colorResidential, $mainOpacity);
                }
            }

            @include media-breakpoint-down(md)
            {
                order: 3;
            }
        }

        &.card-studios
        {
            &:hover
            {
                &::before
                {
                    background-color: rgba($colorStudios, $mainOpacity);
                }
            }

            @include media-breakpoint-down(md)
            {
                order: 2;
            }
        }

        .card-inner
        {
            z-index: 1;
            max-width: 460px;

            :not(h2)
            {
                position: absolute;
                opacity: 0;
                top: -1000vh;
            }

            p,
            h2
            {
                color: $colorInverse;
            }
        }

        @include media-breakpoint-down(md)
        {
            border-bottom: 4px $colorInverse solid;
            padding: 2rem;
        }
    }
}

/* Pages */

.page-banner
{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    h1, h2, .h2
    {
        color: $colorInverse;
        z-index: 1;
        margin-bottom: 6rem;
    }

    h1
    {
        @include media-breakpoint-down(md)
        {
            font-size: 2.5rem;
        }
    }

    .banner-image
    {
        display: flex;
        align-items: flex-end;
        position: relative;
        height: calc(40rem - 15rem);

        &::before
        {
            content: '';
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            background-color: rgba($colorAccent, $mainOpacity);
        }
    }
}

/* Internal Home Pages */

.page-banner.internal-home
{
    height: 40rem;
}

.search-container
{
    position: relative;
    height: calc(40rem - 25rem);
}

.search-overlay
{
    height: 100%;
    display: flex;
    align-items: center;
}

.search-overlay::after
{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.page-search
{
    text-align: center;
    z-index: 1;

    h3
    {
        color: $colorInverse;
    }

    .search-fields
    {
        display: flex;
        justify-content: center;
        gap: 1rem 1.5rem;

        > *
        {
            flex-basis: 25%;
        }

        @include media-breakpoint-down(lg)
        {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-auto-rows: 1fr;

            .btn
            {
                padding: .25rem;
            }

            > .btn:nth-child(3)
            {
                grid-column: span 2;
            }
        }
    }
}

.section-students .page-search .btn:hover i
{
    color: $colorStudents;
}

.section-landlords .page-search .btn:hover i
{
    color: $colorLandlords;
}

.section-residential .page-search .btn:hover i
{
    color: $colorResidential;
}

.section-studios .page-search .btn:hover i
{
    color: $colorStudios;
}

section.icons-container
{
    padding: $containerPaddingY 0;

    h5
    {
        text-transform: uppercase;
    }

    svg
    {
        height: 7rem;
    }

    a
    {
        text-decoration: none;

        i
        {
            color: var(--color-secondary);
            padding-left: .25rem;
            font-size: .85em;
            display: inline-block;
        }
    }
}

.ctas.three-col
{
    grid-template-columns: repeat(3, 1fr);

    @include media-breakpoint-down(xl)
    {
        grid-template-columns: repeat(2, 1fr);

        a:last-child
        {
            grid-column: span 2;
        }

        a > div
        {
            height: 26rem;
        }
    }

    @include media-breakpoint-down(lg)
    {
        grid-template-columns: 1fr;

        a:last-child
        {
            grid-column: auto;
        }

        a > div
        {
            height: 18rem;
        }
    }

    @include media-breakpoint-down(md)
    {
        a > div
        {
            height: 31rem;
        }
    }
}

.ctas.two-col
{
    grid-template-columns: repeat(2, 1fr);

    @include media-breakpoint-down(md)
    {
        grid-template-columns: 1fr;

        a > div
        {
            height: 31rem;
        }
    }
}

.ctas
{
    display: grid;
    gap: 2rem;
    padding-bottom: 2rem;

    a
    {
        text-decoration: none;

        &:hover button
        {
            opacity: 1;
            transition: opacity .7s linear;
        }
    }

    .cta-card
    {
        height: 32rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;

        .card-content
        {
            padding: 0 3rem;
            z-index: 1;
        }

        .btn-container
        {
            position: absolute;
            bottom: 0;
            right: 0;
        }

        button
        {
            opacity: 0;
        }
    }
}

@include media-breakpoint-down(lg)
{
    .existing-clients .existing-content
    {
        text-align: center;
    }
}

/* Student */

.section-students
{
    .search-overlay::after
    {
        background-color: rgba($colorStudents, $mainOpacity);
    }

    .page-search .search-fields select
    {
        color: $colorStudents;

        option
        {
            color: $colorStudents;
        }
    }

    .icons-process svg
    {
        fill: $colorStudents;
    }

    .icons-container svg
    {
        fill: $colorStudents;
    }

    .cta-card.card-premium
    {
        background-image: url("/site/img/students-premium.jpg");
    }

    .cta-card.card-apartments
    {
        background-image: url("/site/img/students-brayford-court.jpg");
    }

    .cta-card.card-shared
    {
        background-image: url("/site/img/students-house-shares.jpg");
    }

    .cta-card.card-students
    {
        background-image: url("/site/img/home-students.jpg");
    }

    .cta-card.card-lincoln-uni
    {
        background-image: url("/images/480/b53c4c.jpg");
    }

    .cta-card.card-bishop-uni
    {
        background-image: url("/images/480/90dfbc.jpg");
    }

    .cta-card.card-lincoln-uni, .cta-card.card-bishop-uni
    {
        &::before
        {
            backdrop-filter: blur(2px);
        }
    }
}

/* Residential */

.section-residential
{
    .search-overlay::after
    {
        background-color: rgba($colorResidential, $mainOpacity);
    }

    .page-search .search-fields select
    {
        color: $colorResidential;

        option
        {
            color: $colorResidential;
        }
    }

    .icons-process svg
    {
        fill: $colorResidential;
    }

    .icons-container svg
    {
        fill: $colorResidential;
    }

    .cta-card.card-residential-new
    {
        background-image: url("/site/img/residential-new.jpg");
    }

    .cta-card.card-residential-existing
    {
        background-image: url("/site/img/residential-existing.jpg");
    }
}

/* Landlords */

.section-landlords
{
    .page-banner.internal-home
    {
        height: 35rem;
    }

    .search-container
    {
        height: 10rem;

        @include media-breakpoint-down(lg)
        {
            height: 15rem;
        }
    }

    .page-search
    {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 3rem;


        .search-fields select
        {
            color: $colorLandlords;

            option
            {
                color: $colorLandlords;
            }
        }

        h3
        {
            margin-bottom: 0;

            @include media-breakpoint-down(lg)
            {
                text-align: left;
            }

            @include media-breakpoint-down(md)
            {
                text-align: center;
                margin-bottom: .5rem;
            }
        }

        @include media-breakpoint-down(md)
        {
            flex-direction: column;
            gap: 0;
        }
    }

    .search-overlay::after
    {
        background-color: rgba($colorLandlords, $mainOpacity);
    }

    .page-search .icons-process svg
    {
        fill: $colorLandlords;
    }

    .icons-container svg
    {
        fill: $colorLandlords;
    }

    .cta-card.card-landlords-new
    {
        background-image: url("/site/img/landlords-new.jpg");
    }

    .cta-card.card-landlords-existing
    {
        background-image: url("/site/img/landlords-existing.jpg");
    }
}

/* Studios */

.section-studios
{
    .search-overlay::after
    {
        background-color: rgba($colorStudios, $mainOpacity);
    }

    .page-search .search-fields select
    {
        color: $colorStudios;

        option
        {
            color: $colorStudios;
        }
    }

    .icons-process svg
    {
        fill: $colorStudios;
    }

    .icons-container svg
    {
        fill: $colorStudios;
    }

    .cta-card.card-residential-new
    {
        background-image: url("/site/img/residential-new.jpg");
    }

    .cta-card.card-residential-existing
    {
        background-image: url("/site/img/residential-existing.jpg");
    }
}

/* Contact Page */

aside.content-contact
{
    margin: 0;

    a.out-of-hours
    {
        font-weight: 800;
        text-transform: lowercase;
        color: $colorStudents;
        text-decoration: none;
        font-size: 1.25rem;
    }
}


/* Contact Buttons */
.section-contact, .profile-actions
{
    background-color: $colorInverse;
    display: flex;
    height: 4rem;
    justify-content: space-around;
    align-items: center;
    font-size: 1.5rem;

    i
    {
        font-size: 1.25rem;
    }

    a
    {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        text-decoration: none;
        color: $colorStudents;

        &:hover
        {
            background-color: $colorStudents;
            color: $colorInverse;
        }

        &:nth-child(even)
        {
            box-shadow: $baseShadow;
        }
    }
}

/* Global Contact Links */

.contact-links
{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: .75rem;
    text-transform: lowercase;
    font-weight: 800;
    font-size: 1.333rem;

    a
    {
        margin-left: 1rem;
        text-decoration: none;
    }

    i
    {
        width: 1rem;
        color: $colorStudents;
    }

    @include media-breakpoint-down(md)
    {
        font-size: 1.25rem;
    }
}

/* Share / Back Snippet */

.item-share
{
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    a
    {
        text-decoration: none;
    }

    h5 i
    {
        color: $colorStudents;
    }
}


/* Share / Back Snippet - Section Colours */

.section-students .item-share i.fa-share-alt,
.section-students .item-share i.la-arrow-left
{
    color: $colorStudents;
}

.section-landlords .item-share i.fa-share-alt,
.section-landlords .item-share i.la-arrow-left
{
    color: $colorLandlords;
}

.section-residential .item-share i.fa-share-alt,
.section-residential .item-share i.la-arrow-left
{
    color: $colorResidential;
}

.section-studios .item-share i.fa-share-alt,
.section-studios .item-share i.la-arrow-left
{
    color: $colorStudios;
}



/* Flex Columns */

.flex-columns
{
    display: flex;
    gap: 2rem;
    text-align: center;
    flex-wrap: wrap;
    justify-content: center;

    .column
    {
        width: 400px;
        background-color: $colorInverse;
        padding: $containerPaddingY;
        outline: $colorStudents 0 solid;
        transition: outline 0.2s linear;

        &:hover
        {
            outline-width: 6px;
        }

        @include media-breakpoint-down(md)
        {
            width: 300px;
            padding: 3rem 2rem;
        }
    }

    a
    {
        text-decoration: none;
        display: flex;
    }

    p
    {
        font-size: .9rem;
    }
}

.flex-columns.form-payments img
{
    height: 12rem;
    padding-bottom: 1.5rem;
}

@import "inc/editor";
@import "inc/js-helpers";
@import "inc/overrides";
@import "inc/ckstyles";

@import "parts/skiplinks";
@import "parts/banner";
@import "parts/christmas-banner";
@import "parts/christmas-banner-images";
@import "parts/header";
@import "parts/buttons";
@import "parts/properties";
@import "parts/latest-properties";
@import "parts/sitemap";
@import "parts/errors";
@import "parts/blog";
@import "parts/blog-faqs";
@import "parts/blog-shared";
@import "parts/staff";
@import "parts/pills";
@import "parts/forms";
@import "parts/breadcrumbs";
@import "parts/fluid-typography";
@import "parts/filter";
@import "parts/why-choose";