header#page-header
{
    z-index: 10;
    background-color: var(--color-secondary);
    position: relative;

    .header-right-col
    {
        display: flex;
        align-items: center;
        gap: .75rem;

        @include media-breakpoint-down(md)
        {
            flex-direction: column;
            gap: 0;
            align-items: end;
        }
    }

    .offcanvas-header
    {
        justify-content: right;

        button.btn-close
        {
            font-size: 1.75rem;
            opacity: 1;
            margin-top: 2rem;
            margin-right: 2rem;
            background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e");
        }
    }

    .offcanvas-body
    {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .header-social
    {
        display: flex;
        gap: .75rem;

        svg
        {
            width: 2rem;
        }
    }

    nav#page-nav
    {
        .navbar-toggler
        {
            border: none;

            .navbar-toggler-icon
            {
                width: 2.5rem;
                height: 2.5rem;
            }
        }
    }

    li
    {
        border-bottom: $colorInverse 1px solid;
        font-size: 1.5rem;
        text-transform: lowercase;
        font-weight: 800;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:last-child
        {
            border: none;
        }

        a
        {
            color: $colorInverse;
            padding: 1.5rem 2rem;
            width: 100%;
            display: flex;
            justify-content: space-between;

            &:hover
            {
                color: $colorStudents;
            }

            &::after
            {
                content: '\f054';
                font-family: FontAwesome;
                color: $colorStudents;
            }
        }
    }

    .section-btns
    {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 2rem;

        .btn
        {
            color: $colorInverse;
            border-color: $colorInverse;

            &:hover
            {
                color: $colorAccent;
                background-color: $colorInverse;
            }
        }
    }

    svg .icon-social
    {
        fill: $colorInverse;
    }

    img
    {
        width: 200px;

        @include media-breakpoint-down(md)
        {
            width: 180px;
        }
    }

    &.page-header-overlay
    {
        .header-logo
        {
            position: absolute;
            width: 15rem;
            top: 2rem;
            left: $containerPaddingX;

            @include media-breakpoint-down(md)
            {
                left: 2rem;
            }
        }

        .header-social
        {
            position: absolute;
            top: 3rem;
            right: 8rem;
            z-index: 100;

            @include media-breakpoint-down(md)
            {
                right: 2rem;
                top: 2rem;
            }
        }

        nav#page-nav
        {
            position: absolute;
            top: 2rem;
            right: $containerPaddingX;

            @include media-breakpoint-down(md)
            {
                top: 4rem;
                right: 1rem;
            }
        }
    }
}

header#page-header:not(.page-header-overlay)
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem $containerPaddingX;

    @include media-breakpoint-down(md)
    {
        padding-left: 2rem;
        padding-right: 2rem;

        #page-nav
        {
            @include media-breakpoint-down(md)
            {
                margin-right: -.75rem;
            }
        }
    }
}

.section-students header#page-header
{
    li a
    {
        &:hover
        {
            color: $colorStudents;
        }

        &::after
        {
            color: $colorStudents;
        }
    }

    .section-btns .btn
    {
        i.fa
        {
            color: $colorStudents;
        }
    }

    .section-contact a
    {
        color: $colorStudents;

        &:hover
        {
            color: $colorInverse;
            background-color: $colorStudents;
        }
    }
}

.section-landlords header#page-header
{
    li a
    {
        &:hover
        {
            color: $colorLandlords;
        }

        &::after
        {
            color: $colorLandlords;
        }
    }

    .section-btns .btn
    {
        i.fa
        {
            color: $colorLandlords;
        }
    }

    .section-contact a
    {
        color: $colorLandlords;

        &:hover
        {
            color: $colorInverse;
            background-color: $colorLandlords;
        }
    }
}

.section-residential header#page-header
{
    li a
    {
        &:hover
        {
            color: $colorResidential;
        }

        &::after
        {
            color: $colorResidential;
        }
    }

    .section-btns .btn
    {
        i.fa
        {
            color: $colorResidential;
        }
    }

    .section-contact a
    {
        color: $colorResidential;

        &:hover
        {
            color: $colorInverse;
            background-color: $colorResidential;
        }
    }
}

.section-studios header#page-header
{
    li a
    {
        &:hover
        {
            color: $colorStudios;
        }

        &::after
        {
            color: $colorStudios;
        }
    }

    .section-btns .btn
    {
        i.fa
        {
            color: $colorStudios;
        }
    }

    .section-contact a
    {
        color: $colorStudios;

        &:hover
        {
            color: $colorInverse;
            background-color: $colorStudios;
        }
    }
}