.faqs-item,
.news-item.news-archive,
.news-item.news-single
{
    a
    {
        text-decoration: none;
    }

    .list-inline-item
    {
        margin-bottom: .25rem;
    }
}

.news-badge
{
    text-transform: lowercase;
    font-size: .75rem;
    padding: .25rem .75rem;
    color: $colorInverse;
    background-color: #000;
    border-radius: 2rem;
    font-weight: 400;

    &.news-badge-date
    {
        background-color: transparent;
        color: $colorAccent;
        padding: 0;
        font-size: 1.25rem;
        text-transform: none;
    }
}

a.news-badge:hover
{
    color: $colorInverse;
}

.news-badge-tag
{
    background-color: $colorAccent;
}

.faqs-item,
.news-item.news-archive
{
    background-color: $colorInverse;
    margin-bottom: 2rem;

    summary
    {
        display: flex;
        align-items: center;
        justify-content: space-between;

        i.fa
        {
            color: $colorAccent;
            font-size: 2rem;
        }
    }
}

/* Category Badeg - Section Colours */

.news-badge-category.cat-students
{
    background-color: $colorStudents;
}

.news-badge-category.cat-landlords
{
    background-color: $colorLandlords;
}

.news-badge-category.cat-residential
{
    background-color: $colorResidential;
}

.news-badge-category.cat-studios
{
    background-color: $colorStudios;
}