.payments,
.form-contact
{
    $color-accent: $colorAccent;
    $color-alternate: $colorAlternate;
    $color-border: #ccc;

    $color-error: #dc3545;
    $color-info: #17a2b8;
    $color-submit: $color-alternate;

    $heading-transform: none;
    $label-transform: none;

    $rounded-borders: false;

    img
    {
        max-width: 100%;
    }

    div.form-description
    {
        > *
        {
            margin: 0 0 1rem;

            &:last-child
            {
                margin: 0;
            }
        }

        margin: 0 0 2rem;
        line-height: 1.667rem;
    }

    ul
    {
        > li
        {
            line-height: 1.5;
            margin-bottom: 1rem;
        }

        &.forms-list > li > a
        {
            text-decoration: none;
        }
    }

    fieldset
    {
        margin-bottom: 3rem;

        &:last-of-type
        {
            margin-bottom: 0;
        }
    }

    .fields
    {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1rem;
        margin-bottom: 1rem;

        &:last-child
        {
            margin-bottom: 0;
        }

        > div
        {
            display: flex;
            flex-direction: column;
            gap: .3333rem;
        }

        @media (min-width: 40rem)
        {
            &.cols-3
            {
                grid-template-columns: repeat(3, 1fr);
            }

            &.cols-2
            {
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }

    legend
    {
        font-weight: 800;
        text-transform: lowercase;
        color: $colorStudents;
        text-align: center;
        font-size: 2rem;
    }

    label
    {
        text-transform: lowercase;
        color: $colorAccent;
        font-size: 1.175rem;
        font-weight: 100;
    }

    .form-control
    {
        box-shadow: none;
    }

    .input-control
    {
        width: 100%;
        padding: .75rem 1rem;
        font-size: 1rem;
        line-height: 1.333;
        position: relative;

        &:active,
        &:focus,
        &:focus-within
        {
            border-color: darken($color-border, 10%);
            box-shadow: 0 0 .25rem rgba(black, 0.075);
        }

        @if ($rounded-borders)
        {
            border-radius: 1.5rem;
        }

        > i.la-angle-down
        {
            position: absolute;
            right: 1rem;
            top: 50%;
            transform: translateY(-50%);
            pointer-events: none;
        }

        option
        {
            text-transform: none;
        }
    }

    .display-control
    {
        font-size: 1rem;
        line-height: 1.333;

        &.display-multiline
        {
            white-space: pre-wrap;
        }
    }

    .input-control
    {
        display: flex;
        justify-content: stretch;
        background-color: $colorInverse;
        border: $colorAccent 4px solid;

        > .prefix,
        > .suffix
        {
            width: auto;
            flex: 0 0 0;
            text-align: center;
            padding: 0 .5rem 0 0;
            color: $colorStudents;
            font-weight: 800;
        }

        > input,
        > textarea,
        > select
        {
            width: 100%;
            padding: 0;
            margin: 0;
            border: 0;
            outline: 0;
            font-size: 1rem;
            font-weight: 800;
            line-height: 1.1;
            color: $colorAccent;
        }
    }

    .field-validation-error + .input-control
    {
        border-color: $color-error;
    }

    .field-helper,
    .field-validation-error
    {
        font-size: .75rem;
        font-weight: bold;

        &:before
        {
            display: inline-block;
            font-family: Line Awesome Free;
            font-weight: 900;
            font-size: 1rem;
            vertical-align: -2px;
            margin-right: .25rem;
        }
    }

    .field-helper
    {
        order: 50;
        color: $colorStudents;

        &:before
        {
            content: "\f05a";
        }
    }

    .field-required
    {
        color: $color-error;
    }

    .field-validation-error
    {
        order: 100;
        color: $color-error;

        &:before
        {
            content: "\f06a";
        }
    }

    button[type=submit]
    {
        width: auto;
    }

    article > footer
    {
        margin-top: 5rem;
    }

    footer
    {
        text-align: center;
        margin-top: 3rem;
    }

    .logo-block
    {
        max-width: 15rem;
        margin: 0 auto;

        > div
        {
            display: grid;
            grid-auto-columns: 1fr;
            grid-auto-flow: column;
            gap: 1rem;
            align-items: center;
            margin-bottom: 1rem;

            &:last-child
            {
                margin-bottom: 0;
            }
        }
    }
}

ul.forms-list
{
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    list-style-type: none;
    text-align: center;
    justify-content: center;
    margin: 2rem 0;

    > li
    {
        flex: 0 1 16rem;
        border: 1px solid $colorAccent;

        > a
        {
            padding: 2rem;
            display: flex;
            flex-direction: column;
            text-decoration: none;
        }
    }

    img
    {
        aspect-ratio: 1 / 1;
        object-fit: contain;
        object-position: center;
    }
}


.search-fields select
{
    height: auto;
}

.search-fields-container select,
.search-fields-container input:not([type='checkbox'])
{
    height: 4rem;
}

.search-overlay select
{
    height: 100%;
    width: 100%;
    appearance: none;
}

.search-fields select,
.search-fields-container select,
.search-fields-container input,
.search-fields-container label,
.search-overlay select
{
    text-transform: uppercase;
    font-weight: 800;
    color: $colorAccent;
    background-color: $colorInverse;
}

.search-fields select,
.search-fields-container select,
.search-fields-container input,
.search-overlay select
{
    box-shadow: $baseShadow;
    text-align: center;
    border: none;
    border-radius: 0;

    option
    {
        text-transform: uppercase;
        font-weight: 800;
        text-align: center;
    }
}

.form-control
{
    border: none;
    border-radius: 0;

    &:focus
    {
        color: $colorAccent;
    }

    option
    {
        text-transform: uppercase;
        font-weight: 800;
        color: $colorAccent;
    }
}

.section-students
{
    .search-fields, .search-fields-container, .search-fields-container, .search-overlay select
    {
        select, input, option, label
        {
            color: $colorStudents;
        }
    }
}

.section-landlords
{
    .search-fields, .search-fields-container, .search-fields-container, .search-overlay select
    {
        select, input, option, label
        {
            color: $colorLandlords;
        }
    }
}

.section-residential
{
    .search-fields, .search-fields-container, .search-fields-container, .search-overlay select
    {
        select, input, option, label
        {
            color: $colorResidential;
        }
    }
}

.section-studios
{
    .search-fields, .search-fields-container, .search-fields-container, .search-overlay select
    {
        select, input, option, label
        {
            color: $colorStudios;
        }
    }
}


.popup-form .modal-content
{
    .modal-body
    {
        padding: $containerPaddingY;
    }

    .form-contact,
    .payments
    {
        label
        {
            font-weight: bold;
        }
    }

    .btn-close
    {
        position: absolute;
        top: 1rem;
        right: 1rem;
    }
}
