div#skiplinks
{
    a,
    a:link,
    a:hover,
    a:visited,
    a:active,
    p
    {
        position: absolute;
        left: 0;
        top: -1000vh;
        width: 1px;
        height: 1px;
        overflow: hidden;
    }
}
